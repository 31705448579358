import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const TallyReport = Loadable(lazy(() => import("./tallyReport")));


const TallyReportRoute = [
    {
        path: '/tallyReport',
        element: <TallyReport />,
    },
]

export default TallyReportRoute
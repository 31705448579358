import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const IntergodownTransferadd = Loadable(
    lazy(() => import('./IntergodownTransferadd'))
)
const IntergodownTransferLogisticView = Loadable(
    lazy(() => import('./IntergodownTransferLogisticView'))
)
// const IntergodownTransferEditForLogisticAndYard= Loadable(
//     lazy(() => import('./IntergodownTransferEditForLogisticAndYard'))
// )
// const ModifiedIntergodownTransferEditForLogisticAndYard = Loadable(
//     lazy(() => import('./IntergodownTransferEditForLogisticAndYard'))
// )
const IntergodownTransfer = Loadable(
    lazy(() => import('./IntergodownTransfer'))
)
const IntergodownTransferEditForLogisticAndYard = Loadable(
    lazy(() => import('./IntergodownTransferEditForLogisticAndYard'))
)
const IntergodownTransferEditForFromYard = Loadable(
    lazy(() => import('./IntergodownTransferEditForFromYard'))
)
const IntergodownTransferEditForAccount = Loadable(
    lazy(() => import('./IntergodownTransferEditForAccount'))
)
const IntergodownTransferEditForToYard = Loadable(
    lazy(() => import('./IntergodownTransferEditForToYard'))
)
const IntergodownTransferAddBatches = Loadable(
    lazy(() => import('./IntergodownTransferAddBatches'))
)


// const IntergodownTransferEditForLogisticAndYard1 = Loadable(
//     lazy(() => import('./IntergodownTransferEditForLogisticAndYard'))
// )

const Intergodownview = Loadable(lazy(() => import('./Intergodownview')))
const IntergodownTransferRoutes = [
    {
        path: '/IntergodownTransfer',
        element: <IntergodownTransfer />,
    },
    {
        path: '/IntergodownTransferadd',
        element: <IntergodownTransferadd />,
    },
    {
        path: '/IntergodownTransferadd/edit/:orderid', 
        element: <IntergodownTransferadd />,
    },
    {
        path: '/IntergodownTransferEditForLogisticAndYard/:logintype/:orderid', 
        element: <IntergodownTransferEditForLogisticAndYard />,
        // element: <ModifiedIntergodownTransferEditForLogisticAndYard />,
    },
    {
        path: '/Intergodownview/view/:orderid',
        element: <Intergodownview />,
    },
    {
        path: '/IntergodownLogisticView/view/:orderid',
        element: <IntergodownTransferLogisticView />,
    },
    {
        path: '/IntergodownTransferadd/:logintype/:orderid',
        element: <IntergodownTransferadd />,
    },
    {
        path: '/IntergodownTransferEditForLogistic/:logintype/:orderid',
        element: <IntergodownTransferEditForLogisticAndYard />,
    },
    {
        path: '/IntergodownTransferEditForLogistic/:logintype/',
        element: <IntergodownTransferEditForLogisticAndYard />,
    },
    {
        path: '/IntergodownTransferEditForFromYard/:logintype/:orderid',
        element: <IntergodownTransferEditForFromYard />,
    },
    {
        path: '/IntergodownTransferAddBatches',
        element: <IntergodownTransferAddBatches />,
    },
    {
        path: '/IntergodownTransferAddBatches/:logintype/:orderid',
        element: <IntergodownTransferAddBatches />,
    },
    {
        path: '/IntergodownTransferEditForAccount/:logintype/:orderid',
        element: <IntergodownTransferEditForAccount />,
    },
    
    // {
    //     path: '/CreateIntergodownTransfer',
    //     element: <CreateIntergodownTransfer/>,
    // },
    // {
    //     path: '/order/add',
    //     element: <Orderadd />,
    // },
    // {
    //     path: '/order/edit/:orderid',
    //     element: <Orderadd />,
    // },
    // {
    //     path: '/order/view/:orderid',
    //     element: <Orderview />,
    // },
    // {
    //     path: '/order/changestatus/:orderid',
    //     element: <Orderstatus />,
    // },
    // {
    //     path: '/order/bmApproval/:orderid',
    //     element: <Orderstatus />,
    // },
    // {
    //     path: '/yard/yardview/:orderid',
    //     element: <Yardview />,
    // },
    // {
    //     path: '/order/delivery/:orderid',
    //     element: <Orderdelivery />,
    // },
    // {
    //     path: '/order/logistic-delivery/:orderid',
    //     element: <LogisticTeamOrder />,
    // }
]

export default IntergodownTransferRoutes

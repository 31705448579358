import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const SupplierUser = Loadable(lazy(() => import("./SupplierUser")));
const SupplierUseradd = Loadable(lazy(() => import("./SupplierUseradd")));
const SupplierUserView = Loadable(lazy(() => import("./SupplierUserView")));

const SupplierUserRoutes = [
    {
        path: '/supplier-users',
        element: <SupplierUser />,
    },
    {
        path: '/supplier-users/add',
        element: <SupplierUseradd />,
    },
    {
        path: '/supplier-users/edit/:customerid',
        element: <SupplierUseradd />,
    },
    {
        path: '/supplier-users/view/:customerid',
        element: <SupplierUserView />,
    },
    {
        path: '/supplier-users/view/:status/:customerid',
        element: <SupplierUserView />,
    },
]

export default SupplierUserRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const DailyProductWiseSalesReport = Loadable(lazy(() => import("./DailyProductWiseSalesReport")));


const DailyProductWiseSalesRoutes = [
    {
        path: '/DailyProductWiseSalesReport',
        element: <DailyProductWiseSalesReport />,
    },
]

export default DailyProductWiseSalesRoutes
import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const SalesReport = Loadable(lazy(() => import("./salesReport")));


const SalesReportRoute = [
    {
        path: '/salesExport',
        element: <SalesReport />,
    },
]

export default SalesReportRoute
import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const StockauditNew = Loadable(lazy(() => import('./StockauditNew')))

const StockauditRoutes = [
    {
        path: '/stockauditnew',
        element: <StockauditNew />,
    },
]

export default StockauditRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const UtilizedAmountImport = Loadable(
    lazy(() => import('./utilizedAmountImport'))
)

const UtilizedAmountImportRount = [
    {
        path: '/utilized-amount',
        element: <UtilizedAmountImport />,
    },
]

export default UtilizedAmountImportRount

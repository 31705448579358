import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const StateWiseRate = Loadable(lazy(() => import("./stateWiseRate")));


const stateWiseRateRoutes = [
    {
        path: '/stateWiseRate',
        element: <StateWiseRate />,
    },
]

export default stateWiseRateRoutes

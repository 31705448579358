import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const BatchWiseSkuWiseReport = Loadable(lazy(() => import("./BatchWise&SKUWiseReport")));
const DataTable = Loadable(lazy(() => import("./DataTable")));


const BatchWiseSkuWiseReportRoutes = [
    {
        path: '/BatchWise&SkuWiseReport',
       // element: <BatchWiseSkuWiseReport />,
       element: <DataTable />,
       
    },
    // {
    //     path: '/BatwisedataTable',
    //     element: <DataTable />,
    // },
]


export default BatchWiseSkuWiseReportRoutes
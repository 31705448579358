import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const OrderRevert = Loadable(lazy(() => import('./OrderRevert')))
const OrderRevertList = Loadable(lazy(() => import('./OrderRevertList')))
const OrderRevertView = Loadable(lazy(() => import('./OrderRevertView')))
const OrderRevertRoute = [
    {
        path: '/orderrevert',
        element: <OrderRevert />,
    },
    {
        path: '/orderreverselist',
        element: <OrderRevertList />,
    },
    {
        path: '/OrderRevertView/:id',
        element: <OrderRevertView />,
    },
]

export default OrderRevertRoute

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const BatchWiseReport = Loadable(lazy(() => import("./BatchWiseReport")));


const BatchWiseReportRoute = [
    {
        path: '/BatchwiseReport',
        element: <BatchWiseReport />,
    },
]

export default BatchWiseReportRoute;
import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const TransactionReport = Loadable(lazy(() => import("./transactionReport")));

const TransactionReportRoute = [
    {
        path: '/transactionReport',
        element: <TransactionReport />,
    },
]

export default TransactionReportRoute;


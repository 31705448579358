import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const WorkOrderList = Loadable(lazy(() => import("./WorkOrderList")));
const WorkOrderCreate = Loadable(lazy(() => import("./WorkOrderCreate")));
const WorkOrderView = Loadable(lazy(() => import("./WorkOrderView")));
const WorkOrderAdminApproval = Loadable(lazy(() => import("./WorkOrderAdminApproval")));

const WorkOrderRoutes = [
    {
        path: '/work-order-new',
        element: <WorkOrderList />,
    },
    {
        path: '/work-order-new/add',
        element: <WorkOrderCreate />,
    },
    {
        path: '/work-order-new/view/:woId',
        element: <WorkOrderView />,
    },
    {
        path: '/work-order-new/view/:woId/:woStatus',
        element: <WorkOrderView />,
    },
    {
        path: '/work-order-admin-approval/:woId/:woStatus',
        element: <WorkOrderAdminApproval />,
    },
]

export default WorkOrderRoutes

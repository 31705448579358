import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const ProductSkuReport = Loadable(lazy(() => import("./ProductSkuReport")));
const ProductSkuViewPage = Loadable(lazy(() => import("./ProductSkuViewReport")));



const ProductSkuReportRoutes = [
    {
        path: '/ProductSkuReport',
        element: <ProductSkuReport />,
    },
    {
        path: '/ProductSkuReport/view/:id',
        element: <ProductSkuViewPage />,
    },
]

export default ProductSkuReportRoutes
import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const DashobardMenumaster = Loadable(lazy(() => import("./DashobardMenumaster")));


const DashboardMenumasterRoutes = [
    {
        path: '/dashobard-menumaster',
        element: <DashobardMenumaster />,
    },
]

export default DashboardMenumasterRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const ProductWiseReport = Loadable(lazy(() => import("./ProductWiseReport")));


const ProductWiseReportRoutes = [
    {
        path: '/ProductWiseReport',
        element: <ProductWiseReport />,
    },
]

export default ProductWiseReportRoutes
import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const Customer = Loadable(lazy(() => import('./CustomerNew')))
const Customeradd = Loadable(lazy(() => import('./Customeradd')))
const CustomerView = Loadable(lazy(() => import('./CustomerView')))

const CustomerRoutes = [
    {
        path: '/customerNew',
        element: <Customer />,
    },
    {
        path: '/customerNew/add',
        element: <Customeradd />,
    },
    {
        path: '/customerNew/edit/:customerid',
        element: <Customeradd />,
    },
    {
        path: '/customerNew/view/:customerid',
        element: <CustomerView />,
    },
    {
        path: '/customerNew/view/:status/:customerid',
        element: <CustomerView />,
    },
]

export default CustomerRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const DailyProductWisePurchaseReport = Loadable(lazy(() => import("./DailyProductwisePurchaseReport")));


const DailyProductWisePurchaseReportRoutes = [
    {
        path: '/DailyProductWisePurchaseReport',
        element: <DailyProductWisePurchaseReport />,
    },
]

export default DailyProductWisePurchaseReportRoutes
import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const Employee = Loadable(lazy(() => import('./Employee')))
const Employeeadd = Loadable(lazy(() => import('./Employeeadd')))
const EmployeeView = Loadable(lazy(() => import('./EmployeeView')))

const employeeRoutes = [
    {
        path: '/employeeNew',
        element: <Employee />,
    },
    {
        path: '/employeeNew/add',
        element: <Employeeadd />,
    },
    {
        path: '/employeeNew/edit/:empid',
        element: <Employeeadd />,
    },
    {
        path: '/employeeNew/view/:empid',
        element: <EmployeeView />,
    },
]

export default employeeRoutes

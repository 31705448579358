import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const AdditionalInward = Loadable(lazy(() => import("./AdditionalInward")));
const AdditionalInwardadd = Loadable(lazy(() => import("./AdditionalInwardadd")));
const AdditionalInwardView= Loadable(lazy(() => import("./AdditionalInwardView")));
const StockinwardRoutes = [
    {
        path: '/additionalinward',
        element: <AdditionalInward />,
    },
    {
        path: '/additionalinward/add',
        element: <AdditionalInwardadd />,
    },
    {
        path: '/additionalinward/view/:id',
        element: <AdditionalInwardView />,
    },
    
]

export default StockinwardRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const BMCreditForm = Loadable(lazy(() => import("./BMCreditForm")));
const BMCreditFormAdd = Loadable(lazy(() => import("./BMCreditFormAdd")));
const BMCreditFormView = Loadable(lazy(() => import("./BMCreditFormView")));

const BMCreditFormRoutes = [
    {
        path: '/credit-limit',
        element: <BMCreditForm />,
    },
    {
        path: '/credit-limit/add',
        element: <BMCreditFormAdd />,
    },
    {
        path: '/credit-limit/edit/:editid',
        element: <BMCreditFormAdd />,
    },
    {
        path: '/credit-limit/view/:editid',
        element: <BMCreditFormView />,
    },
    {
        path: '/credit-limit/edit/:userType/:editid',
        element: <BMCreditFormAdd />,
    },
]

export default BMCreditFormRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const SkuMasters = Loadable(lazy(() => import('./SkuMasters')))
const SkuAdd = Loadable(lazy(() => import('./SkuAdd')))
const SkuView = Loadable(lazy(() => import('./SkuView')))

const SkuMastersRoutes = [
    {
        path: '/SkuMastersNew',
        element: <SkuMasters />,
    },
    {
        path: '/Sku/addnew',
        element: <SkuAdd />,
    },
    {
        path: '/Sku/editnew/:skuid',
        element: <SkuAdd />,
    },
    {
        path: '/Sku/viewnew/:skuid',
        element: <SkuView />,
    },
]

export default SkuMastersRoutes

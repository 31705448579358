import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const Profile = Loadable(lazy(() => import("./Profile")));

const ProfileRoutes = [
    {
        path: '/profile',
        element: <Profile />,
    },
    {
        path: '/profile/edit',
        element: <Profile />,
    },
    {
        path: '/profile/edit/:empid',
        element: <Profile />,
    },
]

export default ProfileRoutes;
import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
const SalesReturnadd = Loadable(
    lazy(() => import('./SalesReturnadd'))
)
const SalesReturn = Loadable(
    lazy(() => import('./SalesReturn'))
)
const SalesYardView = Loadable(
    lazy(() => import('./SalesYardView'))
)

const SalesReturnview = Loadable(lazy(() => import('./SalesReturnview')))
const SalesReturnRoutes = [
    
    {
        path: '/sales-return',
        element: <SalesReturn />,
    },
    {
        path: '/sales-return/add',
        element: <SalesReturnadd />,
    },
    {
        path: '/sales-return/edit/:orderid',
        element: <SalesReturnadd />,
    },
    // {
    //     path: '/sales-return/edit/:orderid',
    //     element: <SalesReturnStatus />,
    // },
    // {
    //     path: '/sales-return/edit1//:userType/:orderid',
    //     element: <SalesReturnStatus />,
    // },
    {
        path: '/sales-return/edit/:userType/:orderid',
        element: <SalesReturnadd />,
    },
    {
        path: '/sales-return/sales-return-view/:userType/:orderid',
        element: <SalesYardView />,
    },
    {
        path: '/sales-return/view/:orderid',
        element: <SalesReturnview />,
    },
    {
        path: '/sales-return/view/:userType/:orderid',
        element: <SalesReturnview />,
    },
    {
        path: '/sales-return/bm-approve/:orderid',
        element: <SalesReturnadd />,
    },
]

export default SalesReturnRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const CustomerReciept = Loadable(lazy(() => import("./CustomerReciept")));
const CustomerRecieptAdd = Loadable(lazy(() => import("./CustomerRecieptAdd")));
const CustomerRecieptView = Loadable(lazy(() => import("./CustomerRecieptView")));
const CustomerRecieptRoutes = [
    {
        path: '/customer-reciept',
        element: <CustomerReciept />,
    },
    {
        path: '/customer-reciept/add',
        element: <CustomerRecieptAdd />,
    },
    {
        path: '/customer-reciept/edit/:recieptid',
        element: <CustomerRecieptAdd />,
    },
    {
        path: '/customer-reciept/view/:recieptid',
        element: <CustomerRecieptView />,
    },
    {
        path: '/customer-reciept/view/:userType/:recieptid',
        element: <CustomerRecieptView />,
    }
]
export default CustomerRecieptRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const Order = Loadable(lazy(() => import("./Order")));
const Orderadd = Loadable(lazy(() => import("./Orderadd")));
const Orderview = Loadable(lazy(() => import("./Orderview")));
const WorkOrderRoutes = [
    {
        path: '/work-order',
        element: <Order />,
    },
    {
        path: '/work-order/add',
        element: <Orderadd />,
    },
    {
        path: '/work-order/edit/:orderid',
        element: <Orderadd />,
    },
    {
        path: '/work-order/view/:orderid',
        element: <Orderview />,
    },
    {
        path: '/work-order/view/:userType/:orderid',
        element: <Orderview />,
    }
]

export default WorkOrderRoutes

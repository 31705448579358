import React from 'react'
import { Box, styled } from '@mui/system'
import { CircularProgress } from '@mui/material'
import logo from "Assets/image/loader.jpeg"
const StyledLoading = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'rgba(100,100,100, 0.5)',
    
//   -webkit-filter: "blur(8px)"
    
    '& img': {
        width: 'auto',
        height: '60px',
    },
    "& .circleProgress": {
        position: 'absolute',
        left: 5,
        right: 0,
        top: 'calc(50% - 20px)',
    }
}))

const Loading = () => {

    return (
        <StyledLoading>
            <Box position="relative">
                <img src={logo} alt="" />
                <CircularProgress className="circleProgress" />
            </Box>
        </StyledLoading>
    )
}

export default Loading

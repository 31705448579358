import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const VendorUser = Loadable(lazy(() => import("./VendorUser")));
const VendorUseradd = Loadable(lazy(() => import("./VendorUseradd")));
const VendorUserView = Loadable(lazy(() => import("./VendorUserView")));

const VendorUserRoutes = [
    {
        path: '/vendor-users',
        element: <VendorUser />,
    },
    {
        path: '/vendor-users/add',
        element: <VendorUseradd />,
    },
    {
        path: '/vendor-users/edit/:customerid',
        element: <VendorUseradd />,
    },
    {
        path: '/vendor-users/view/:customerid',
        element: <VendorUserView />,
    },
    {
        path: '/vendor-users/view/:status/:customerid',
        element: <VendorUserView />,
    },
]

export default VendorUserRoutes
